var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infobox" }, [
      _c("img", { attrs: { src: require("@/assets/images/baofei.jpg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }